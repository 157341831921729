import { useState } from 'react';

export const useModalWrapper = () => {
  const [modalOpened, setModalOpened] = useState(false);

  const onOpenModal = () => setModalOpened(true);
  const onCloseModal = () => setModalOpened(false);

  return { modalOpened, onOpenModal, onCloseModal };
};
