import { SlideImage } from './types';

const introImages: SlideImage[] = [
  {
    imageName: 'intro/0.jpg',
    caption: 'Tutorial -TW Timer Quick Tour',
  },
  {
    imageName: 'intro/2.jpg',
    caption: 'This app is like an alarm clock',
  },
  {
    imageName: 'intro/3.jpg',
    caption: 'with tasks that need to be completed',
  },
  {
    imageName: 'intro/5.jpg',
    caption: 'but they are specifically related',
  },
  {
    imageName: 'intro/4.jpg',
    caption: 'to the online game Tribalwars.',
  },
  {
    imageName: 'intro/6.jpg',
    caption: 'Instead of setting up an alarm',
  },
  {
    imageName: 'intro/7.jpg',
    caption: 'every time you need to do something timed',
  },
  {
    imageName: 'intro/8.jpg',
    caption: 'you can put it all here',
  },
  {
    imageName: 'intro/9.jpg',
    caption: 'with only a few clicks.',
  },
  {
    imageName: 'intro/10.jpg',
    caption: 'The app understands input from various TW scripts',
  },
  {
    imageName: 'intro/11.jpg',
    caption: 'or selected pages from the game',
  },
  {
    imageName: 'intro/12.jpg',
    caption: 'or even simple reminder commands',
  },
  {
    imageName: 'intro/13.jpg',
    caption: 'and then parses it into timed tasks.',
  },
  {
    imageName: 'intro/14.jpg',
    caption: 'You can sort them by type or world,',
  },
  {
    imageName: 'intro/15.jpg',
    caption: 'check their details,',
  },
  {
    imageName: 'intro/16.jpg',
    caption: 'mark them as completed,',
  },
  {
    imageName: 'intro/17.jpg',
    caption: 'or delete them completely.',
  },
  {
    imageName: 'intro/18.jpg',
    caption: "You don't need to create an account,",
  },
  {
    imageName: 'intro/19.jpg',
    caption: 'your tasks will be saved in your browser',
  },
  {
    imageName: 'intro/0.jpg',
    caption: 'and will be there every time you open it',
  },
  {
    imageName: 'intro/21.jpg',
    caption: 'unless you clear your cache of course!',
  },
  {
    imageName: 'intro/22.jpg',
    caption: 'If you do need to share them between devices',
  },
  {
    imageName: 'intro/23.jpg',
    caption: 'you can use the Connect option',
  },
  {
    imageName: 'intro/24.jpg',
    caption: 'it will give you a special key',
  },
  {
    imageName: 'intro/25.jpg',
    caption: 'and with it you can connect other devices/browsers',
  },
  {
    imageName: 'intro/26.jpg',
    caption: 'for more information and tutorials check the Help page',
  },
];

export { introImages };
